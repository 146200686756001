<template>
	<NuxtLink
			:to="actionDetailLink(action.id, rieId, companyId)"
			class="action"
			:data-test-id-entity="action.id"
			:data-test-status="statusProcessed.text"
	>
		<div class="cell description">
			<span class="icon"><IconSvg icon="calendar" /></span>
			<span class="text" data-test-id="action-description">{{ action.description }}</span>
		</div>
		<div class="cell date">
			{{ formatDate(action.endDate) }}
			<span
					v-if="action.repeat !== 'NON_REPEATING'"
					:title="getActionRepeatText(action.repeat)"
					class="icon"
			>
				<IconSvg icon="repeatable" />
			</span>
		</div>

		<div class="cell responsible">
			{{ action.responsible }}
		</div>
		<div :class="statusProcessed.bgClass" class="cell status">
			<div class="card-only">
				<BadgeLabel text="Status" class="card-only" />
				<ActionStatusBadge :action="action" />
			</div>

			<div class="table-only">
				<template v-if="action.status === 'CLOSED'">
					<span class="sr-only">{{ statusProcessed.text }}</span>
					<IconSvg icon="check-circle" />
				</template>
				<span v-else>
					{{ statusProcessed.text }}
				</span>
			</div>
		</div>
		<div class="cell cta">
			<IconSvg icon="chevron-right" />
		</div>
	</NuxtLink>
</template>
<script lang="ts" setup>
	import type {PropType} from 'vue';
	import {computed} from 'vue';
	import {actionDetailLink} from '~/lib/pageHelper';
	import {
		getActionRepeatText,
		getProcessedActionStatus
	} from '~/lib/actionHelper';
	import type {FlatActionOverviewItem} from '~/lib/types/actionTypes';
	import IconSvg from '~/components/generic/IconSvg.vue';
	import ActionStatusBadge from '~/components/actions/ActionStatusBadge.vue';
	import DateHelper from '~/utils/DateHelper';
	import BadgeLabel from '~/components/generic/badge/BadgeLabel.vue';

	type UsedActionProperties = 'id' | 'description' | 'endDate' | 'repeat' | 'responsible' | 'status';
	type MinimalActionForOverview = Pick<FlatActionOverviewItem, UsedActionProperties>;

	const props = defineProps({
		action: {
			type: Object as PropType<MinimalActionForOverview>,
			required: true
		},
		rieId: {
			type: String,
			required: false,
			default: undefined
		},
		companyId: {
			type: String,
			required: false,
			default: undefined
		}
	});

	const statusProcessed = computed(() => getProcessedActionStatus(props.action));

	const formatDate = (date: string) => {
		return DateHelper.getFormattedDate(date, 'DD-MM-YYYY');
	};
</script>

<style scoped>
	.action {
		display: grid;
		grid-template: var(--action-overview-grid);
		color: inherit;
		text-decoration: none;
		font-size: var(--font-size-p-2);
		line-height: var(--line-height-p-2);
		border-top: 1px solid var(--color-grey-100);
		--icon-size: 16px;
		width: 100%;
		content-visibility: auto;
	}

	.description {
		grid-area: description;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.date {
		grid-area: date;
	}

	.responsible {
		grid-area: responsible;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.status {
		grid-area: status;
		text-align: center;
	}

	.cta {
		grid-area: cta;
		--icon-size: 24px;
		padding: 0;

		display: grid;

		align-items: stretch;
		justify-items: stretch;
	}

	@media screen and (max-width: 869px) {
		.action {
			padding: 10px 10px 10px 40px;
			position: relative;
			gap: 8px;

			align-items: center;
			justify-items: start;
		}

		:deep(.calendar-icon) {
			position: absolute;
			left: 10px;
			top: 10px;
			--icon-size: 20px;
		}

		.date {
			font-weight: bold;
			font-size: 15px;
			line-height: 18px;

		}

		.date:deep(.history-icon) {
			--icon-size: 20px;
			margin-left: 8px;
			vertical-align: 1px;
		}

		.responsible {
			font-size: 13px;
			line-height: 16px;
		}

		.description {
			font-size: var(--font-size-p-1);
			line-height: var(--line-height-p-1);
			margin-bottom: 8px;
		}

		.status {
			align-self: start;
			justify-self: start;
			text-align: left;
		}

		.cta {
			--icon-size: 24px;
		}

		.table-only {
			display: none;
		}
	}

	@media screen and (min-width: 870px) {
		.cell {
			padding: var(--cell-padding-y, 20px) var(--cell-padding-x, 10px);
			white-space: nowrap;
			align-self: center;
		}

		.description {
			justify-self: auto;
			padding-left: 1rem;

			display: flex;
			gap: 16px;
			--icon-size: 16px;

			.icon {
				flex: 0 0 auto;
			}

			.text {
				flex: 0 1 auto;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&:deep(calendar-icon) {
				margin-right: 12px;
				--icon-size: 16px;
			}
		}

		.status {
			background: var(--color-grey-50);
			font-weight: 500;

			align-self: stretch;
			justify-self: stretch;
		}

		.cta {
			padding: 0;
			justify-self: center;
		}

		.card-only {
			display: none;
		}

		.status.success {
			background: var(--color-success-light);
			font-weight: bold;
			color: var(--color-success);
		}

		.status.error {
			background: var(--color-error-light);
			font-weight: bold;
		}

		.status.warning {
			background: var(--color-warning-light);
			font-weight: bold;
		}
	}
</style>
